import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { BlockTitle } from "../../../components/Component";
import { Button } from "reactstrap";
import { useNavigate } from "react-router";
import ReactPlayer from "react-player";
import { Links } from "../../../jsonfiles/Links"
import about from "../../../jsonfiles/About.json"

const VideoSlider = () => {
  const navigate = useNavigate();
  const vid1= Links?.About[0].url; 
  const vid2= Links?.About[1].url; 
  const vid3=Links?.About[2].url;
  const vid5= Links?.About[3].url;
  const vid6= Links?.About[4].url;
  return (
    <>
      <Swiper
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper h-100"
      >
        <SwiperSlide className="align-center justify-content-center d-flex">
          <video
            playsInline
            autoPlay
            loop
            muted
            width="100%"
            height="600vh"
            className="opacity-75"
            style={{ filter: "brightness(1.5) opacity(0.5)", objectFit: "cover" }}
          >
            <source src={vid5} type="video/mp4" />
            <source src={vid5} type="video/ogg" />
            Your browser does not support the video.
          </video>
          <div className="position-absolute flex-column align-start justify-center mx-md-5 mx-3 px-md-5 px-3 h-100 text-white">
            <div className="my-md-3 my-0">
              <span className="fs-1 d-none d-md-block">{about?.CRM?.section1.map((item)=>item?.video4title)}</span>
              <span className="fs-3 d-md-none">{about?.CRM?.section1.map((item)=>item?.video4title)}</span>
            </div>
            <div className="my-md-3 my-0">
              <span className="fs-5 w-100  d-none d-md-block">
              {about?.CRM?.section1.map((item)=>item?.video4text)}
              </span>
              <span className="fs-6 w-100 d-md-none">
              {about?.CRM?.section1.map((item)=>item?.video4textsec)}
              </span>
            </div>
            <div className="my-md-3 my-1">
              <Button
                className="w-150px justify-center rounded-3 py-2"
                color="primary"
                onClick={() => navigate("/dj-profiles/1")}
              >
                {about?.CRM?.section1.map((item)=>item?.buttontext)}
              </Button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="align-center justify-content-center d-flex">
          <video
            playsInline
            autoPlay
            loop
            muted
            width="100%"
            height="600vh"
            className="opacity-75"
            style={{ filter: "brightness(1.5) opacity(0.5)", objectFit: "cover" }}
          >
            <source src={vid2} type="video/mp4" />
            <source src={vid2} type="video/ogg" />
            Your browser does not support the video.
          </video>
          <div className="position-absolute flex-column align-start justify-center mx-md-5 mx-3 px-md-5 px-3 h-100 text-white">
            <div className="my-md-3 my-0">
              <span className="fs-1 d-none d-md-block">{about?.CRM?.section1.map((item)=>item?.video2title)}</span>
              <span className="fs-3 d-md-none">{about?.CRM?.section1.map((item)=>item?.video2title)}</span>
            </div>
            <div className="my-md-3 my-0">
              <span className="fs-5 w-100 d-none d-md-block">
              {about?.CRM?.section1.map((item)=>item?.video2text)}
              </span>
              <span className="fs-6 w-100 d-md-none">
              {about?.CRM?.section1.map((item)=>item?.video2text)}
              </span>
            </div>
            <div className="my-md-3 my-1">
              <Button
                className="w-150px justify-center rounded-3 py-2"
                color="primary"
                onClick={() => navigate("/dj-profiles/1")}
              >
                {about?.CRM?.section1.map((item)=>item?.buttontext)}
              </Button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="align-center justify-content-center d-flex">
          <video
            playsInline
            autoPlay
            loop
            muted
            width="100%"
            height="600vh"
            className="opacity-75"
            style={{ filter: "brightness(1.5) opacity(0.5)", objectFit: "cover" }}
          >
            <source src={vid6} type="video/mp4" />
            <source src={vid6} type="video/ogg" />
            Your browser does not support the video.
          </video>
          <div className="position-absolute flex-column align-start justify-center mx-md-5 mx-3 px-md-5 px-3 h-100 text-white">
            <div className="my-md-3 my-0">
              <span className="fs-1 d-none d-md-block">{about?.CRM?.section1.map((item)=>item?.video5title)}</span>
              <span className="fs-5 d-md-none">{about?.CRM?.section1.map((item)=>item?.video5title)}</span>
            </div>
            <div className="my-md-3 my-0">
              <span className="fs-5 w-100 d-none d-md-block">
              {about?.CRM?.section1.map((item)=>item?.video5text)}
              </span>
              <span className="fs-5 w-100 d-md-none">
              {about?.CRM?.section1.map((item)=>item?.video5text)}
              </span>
            </div>
            <div className="my-md-3 my-1">
              <Button
                className="w-150px justify-center rounded-3 py-2"
                color="primary"
                onClick={() => navigate("/dj-profiles/1")}
              >
                {about?.CRM?.section1.map((item)=>item?.buttontext)}
              </Button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="align-center justify-content-center d-flex">
          <video
            playsInline
            autoPlay
            loop
            muted
            width="100%"
            height="600vh"
            className="opacity-75"
            style={{ filter: "brightness(1.5) opacity(0.5)", objectFit: "cover" }}
          >
            <source src={vid3} type="video/mp4" />
            <source src={vid3} type="video/ogg" />
            Your browser does not support the video.
          </video>
          <div className="position-absolute flex-column align-start justify-center mx-md-5 mx-3 px-md-5 px-3 h-100 text-white">
            <div className="my-md-3 my-0">
              <span className="fs-1 d-md-block d-none">{about?.CRM?.section1.map((item)=>item?.video3title)}</span>
              <span className="fs-3 d-md-none">{about?.CRM?.section1.map((item)=>item?.video3title)}</span>
            </div>
            <div className="my-md-3 my-0">
              <span className="fs-5 w-100  d-none d-md-block">
                B{about?.CRM?.section1.map((item)=>item?.video3text)}
              </span>
              <span className="fs-6 w-100 d-md-none">
              {about?.CRM?.section1.map((item)=>item?.video3title)}
              </span>
            </div>
            <div className="my-md-3 my-1">
              <Button
                className="w-150px justify-center rounded-3 py-2"
                color="primary"
                onClick={() => navigate("/dj-profiles/1")}
              >
                {about?.CRM?.section1.map((item)=>item?.buttontext)}
              </Button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="align-center justify-content-center d-flex">
          <video
            playsInline
            autoPlay
            loop
            muted
            width="100%"
            height="600vh"
            className="opacity-75"
            style={{ filter: "brightness(1.5) opacity(0.5)", objectFit: "cover" }}
          >
            <source src={vid1} type="video/mp4" />
            <source src={vid1} type="video/ogg" />
            Your browser does not support the video.
          </video>
          <div className="position-absolute flex-column align-start justify-center mx-md-5 mx-3 px-md-5 px-3 h-100 text-white">
            <div className="my-md-3 my-0">
              <span className="fs-1 d-none d-md-block">{about?.CRM?.section1.map((item)=>item?.video1title)}</span>
              <span className="fs-3 d-md-none">{about?.CRM?.section1.map((item)=>item?.video1title)}</span>
            </div>
            <div className="my-md-3 my-0">
              <span className="fs-5 w-100  d-none d-md-block">
              {about?.CRM?.section1.map((item)=>item?.video1text)}
              </span>
              <span className="fs-6 w-100 d-md-none">
              {about?.CRM?.section1.map((item)=>item?.video1text)}
              </span>
            </div>
            <div className="my-md-3 my-1">
              <Button
                className="w-150px justify-center rounded-3 py-2"
                color="primary"
                onClick={() => navigate("/dj-profiles/1")}
              >
               {about?.CRM?.section1.map((item)=>item?.buttontext)}
              </Button>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default VideoSlider;
