import React, { useContext, useEffect } from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Block, BlockTitle, Col, Icon, PreviewCard, Row } from "../../components/Component";
import Content from "../../layout/content/Content";
import { Card, Nav, NavItem } from "reactstrap";
import { Link } from "react-scroll";
import FAQ from "../Individual DJ Pages/FAQ";
import HeroSectionImage from "../../images/DJ_Section2.png";
import { NavLink } from "react-router-dom";
import { createcontext } from "../../layout/Index";
import djonlyclient from "../../jsonfiles/DjonlyClient.json"

const ClientTimeLine = () => {
  const { heroSection, setHeroSection } = useContext(createcontext);

  useEffect(() => {
    setHeroSection(true);
  }, []);
  return (
    <>
      <Content>
        <BlockTitle page>
          <h3>
          {djonlyclient?.CRM?.section1?.map((item)=>item?.title)}<span className="text-primary fw-medium"> {process.env.REACT_APP_TITLE}{" "} </span>
          </h3>
        </BlockTitle>
        <PreviewCard className="my-2">
          <Row>
            {/* <Col lg={6}>
              <div>
                <img src={HeroSectionImage} alt="DJ Hero Section Image" className="img-fluid" />
              </div>
            </Col> */}
            <Col>
              <Block className="border-0 px-5">
                <Card className="p-3 fs-5  align-center">
                  <BlockTitle className="my-2">{djonlyclient?.CRM?.section1?.map((item)=>item?.question1)}</BlockTitle>
                  <div className="my-2">
                    <p>
                      {/* Start by visiting the{" "}
                      <NavLink to={`${process.env.REACT_APP_PUBLIC_URL}`}>
                        <span className="text-primary fw-medium" style={{ cursor: "pointer" }}>
                          {process.env.REACT_APP_TITLE}
                        </span>{" "}
                      </NavLink>{" "}
                      website and clicking on 'Sign In'. Complete the registration form with your personal details and
                      verify your email address to begin browsing and booking DJs for your events. Start by going to the{" "} */}
                      {djonlyclient?.CRM?.section1?.map((item)=>item?.text1)}
                    </p>
                  </div>
                  <div className="my-1">
                    <Nav tabs style={{ cursor: "pointer" }} className="border-0">
                      <NavItem className="p-0">
                        <NavLink
                          to={`${process.env.REACT_APP_ACCOUNT_URL}login`}
                          spy={true}
                          className="fw-medium fs-5 btn btn-primary"
                        >
                         {djonlyclient?.CRM?.section1?.map((item)=>item?.buttontext1)}
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Card>
              </Block>
            </Col>
          </Row>
        </PreviewCard>
        <div id="DjReg" className="my-2">
          <PreviewCard>
            <BlockTitle className="my-3 text-center">{djonlyclient?.CRM?.section2?.map((item)=>item?.steptext)}</BlockTitle>
            <Block>
              <VerticalTimeline lineColor={"#364a63"}>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  iconStyle={{ background: "#ff1300", color: "#fff", border: "3px solid #364a63", boxShadow: "none" }}
                  icon={<span className="fs-1 justify-content-center align-center h-100">1</span>}
                >
                  <h3 className="vertical-timeline-element-title fs-3" style={{ color: "#ff1300" }}>
                  {djonlyclient?.CRM?.section2?.map((item)=>item?.step1title)}
                  </h3>
                  <p>{djonlyclient?.CRM?.section2?.map((item)=>item?.step1text)}</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  iconStyle={{ background: "#ff1300", color: "#fff", border: "3px solid #364a63", boxShadow: "none" }}
                  icon={<span className="fs-1 justify-content-center align-center h-100">2</span>}
                >
                  <h3 className="vertical-timeline-element-title fs-3" style={{ color: "#ff1300" }}>
                  {djonlyclient?.CRM?.section2?.map((item)=>item?.step2title)}
                  </h3>
                  <p> {djonlyclient?.CRM?.section2?.map((item)=>item?.step2text)}</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  iconStyle={{ background: "#ff1300", color: "#fff", border: "3px solid #364a63", boxShadow: "none" }}
                  icon={<span className="fs-1 justify-content-center align-center h-100">3</span>}
                >
                  <h3 className="vertical-timeline-element-title fs-3" style={{ color: "#ff1300" }}>
                  {djonlyclient?.CRM?.section2?.map((item)=>item?.step3title)}
                  </h3>
                  <h4 className="vertical-timeline-element-subtitle"></h4>
                  <p>
                  {djonlyclient?.CRM?.section2?.map((item)=>item?.step3text)}
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  iconStyle={{ background: "#ff1300", color: "#fff", border: "3px solid #364a63", boxShadow: "none" }}
                  icon={<span className="fs-1 justify-content-center align-center h-100">4</span>}
                >
                  <h3 className="vertical-timeline-element-title fs-3" style={{ color: "#ff1300" }}>
                  {djonlyclient?.CRM?.section2?.map((item)=>item?.step4title)}
                  </h3>
                  <p>
                  {djonlyclient?.CRM?.section2?.map((item)=>item?.step4text)}
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  iconStyle={{ background: "#ff1300", color: "#fff", border: "3px solid #364a63", boxShadow: "none" }}
                  icon={
                    <Icon name="arrow-down-round-fill" className="fs-1 justify-content-center align-center h-100" />
                  }
                />
              </VerticalTimeline>
              <h3 className="vertical-timeline-element-title text-center fs-3 my-2">
              {djonlyclient?.CRM?.section2?.map((item)=>item?.text2)} <span className="text-primary fw-medium"> {process.env.REACT_APP_TITLE}{" "} </span>
              </h3>
              <h3 className="vertical-timeline-element-title text-center fs-4 fw-normal my-3">
                <NavLink
                  to={`${process.env.REACT_APP_ACCOUNT_URL}register?type=client`}
                  className="btn btn-primary text-white fs-5 p-2 px-3"
                >
                  {djonlyclient?.CRM?.section2?.map((item)=>item?.buttontext2)}
                </NavLink>
              </h3>
            </Block>
          </PreviewCard>
        </div>
        <PreviewCard>
          <Row className="justify-around my-3 mx-2">
            <Col lg={3} className="justify-center my-1">
              <div className="carduidjsection">
                <div className="d-flex flex-column justify-center align-center h-100 p-3">
                  <span className="fw-medium lead-text" style={{ color: "#00ff58", fontSize: "40px" }}>
                  {djonlyclient?.CRM?.section3?.map((item)=>item?.card1)}
                  </span>
                  <span className="text-white fw-medium fs-5">{djonlyclient?.CRM?.section3?.map((item)=>item?.card1text)}</span>
                </div>
              </div>
            </Col>
            <Col lg={3} className="justify-center my-1">
              <div className="carduidjsection">
                <div className="d-flex flex-column justify-center align-center h-100 p-3">
                  <span className="fw-medium lead-text" style={{ color: "#00ff58", fontSize: "40px" }}>
                  {djonlyclient?.CRM?.section3?.map((item)=>item?.card2)}
                  </span>
                  <span className="text-white fw-medium fs-5">{djonlyclient?.CRM?.section3?.map((item)=>item?.card2text)}</span>
                </div>
              </div>
            </Col>
            <Col lg={3} className="justify-center my-1">
              <div className="carduidjsection">
                <div className="d-flex flex-column justify-center align-center h-100 p-3">
                  <span className="fw-medium lead-text" style={{ color: "#00ff58", fontSize: "40px" }}>
                  {djonlyclient?.CRM?.section3?.map((item)=>item?.card3)}
                  </span>
                  <span className="text-white fw-medium fs-5">{djonlyclient?.CRM?.section3?.map((item)=>item?.card3text)}</span>
                </div>
              </div>
            </Col>
            <Col lg={3} className="justify-center my-1">
              <div className="carduidjsection">
                <div className="d-flex flex-column justify-center align-center h-100 p-3">
                  <span className="fw-medium lead-text" style={{ color: "#00ff58", fontSize: "40px" }}>
                  {djonlyclient?.CRM?.section3?.map((item)=>item?.card4)}
                  </span>
                  <span className="text-white fw-medium fs-5">{djonlyclient?.CRM?.section3?.map((item)=>item?.card4text)}</span>
                </div>
              </div>
            </Col>
          </Row>
        </PreviewCard>
        <div className="my-2">
          <FAQ />
        </div>
      </Content>
    </>
  );
};

export default ClientTimeLine;
