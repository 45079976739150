import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Card, CardBody, CardImg, CardTitle, CardText, Button, Col, Row } from "reactstrap";
import PlaceHolderImage from "../../images/Profile_Picture.png";
import { ShimmerPostItem } from "react-shimmer-effects";
import CookieSiteSettings from "../../utils/cookieUtils";

const ImagesView = ({ profiles, companiesListLoading }) => {
  const [currency, setCurrency] = useState(null);
  useEffect(() => {
    const getCurrency = async () => {
      const currencyValue = CookieSiteSettings();
      setCurrency(currencyValue?.other?.currency);
    };
    getCurrency();
  }, []);
  const navigate = useNavigate();
  return (
    <>
      {companiesListLoading ? (
        <div className="d-flex flex-column justify-center align-center">
          {Array.from({ length: 6 }).map((_, index) => (
            <ShimmerPostItem card title cta />
          ))}
        </div>
      ) : (
        <div>
          <Row className="g-1 mt-1 overflow-auto" style={{ height: "830px", scrollbarWidth: "none" }}>
            {profiles.map((profile) => (
              <Col
                md={4}
                xl={4}
                key={profile?.id}
                className="shadow-sm mb-4"
                onClick={() => navigate(`/dj-profiles/specificDj/${profile?.id}`)}
                style={{ cursor: "pointer" }}
              >
                <Card className="shadow-sm h-100">
                  <CardImg
                    top
                    src={profile?.profile_image_path || PlaceHolderImage}
                    alt={profile?.company_name || "----"}
                    className="rounded-top "
                    style={{ height: "250px", objectFit: "cover" }}
                  />
                  <CardBody className="p-4 d-flex flex-column justify-content-between">
                    <div className="">
                      <CardTitle tag="h5" className="mb-1">
                        {profile?.company_name || "----"}
                      </CardTitle>
                      <CardText className="text-muted mb-1">
                        {profile?.city || "----"},{profile?.state || "----"}
                      </CardText>
                      <p className="fs-5 text-secondary" style={{ height: "90px", overflow: "hidden" }}>
                        {profile?.uniqueness && profile?.uniqueness.length > 80 ? (
                          <>
                            {profile?.uniqueness.substring(0, 80)}
                            <span className="text-primary fw-bold">...</span>
                          </>
                        ) : (
                          profile?.uniqueness || "----"
                        )}
                      </p>
                      <CardText className="mb-0 ">
                        Starting from: {currency}{" "}
                        {new Intl.NumberFormat("en-IN", { currency: "INR" }).format(
                          profile?.max_packages?.[0]?.min_package_price || 0
                        )}
                      </CardText>
                    </div>
                    <div className="mt-3">
                      <Button
                        color="primary"
                        className="me-2 rounded-pill w-100 d-flex justify-content-center"
                        onClick={() => navigate(`/dj-profiles/specificDj/${profile?.id}`)}
                      >
                        View Details
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </>
  );
};

export default ImagesView;
