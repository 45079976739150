import React, { useContext, useEffect, useRef, useState } from "react";
import { Block, BlockTitle } from "../../components/Component";
import { Button, Card, Col, Row, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
// import services2 from "../../images/contact_us2.jpg";
import "../../css/style.css";
import RoleSection from "../Individual DJ Pages/RoleSection";
import ReCAPTCHA from "react-google-recaptcha";
import { createcontext } from "../../layout/Index";
import { Links } from "../../jsonfiles/Links";
import Select, { components } from "react-select";
import contact from "../../jsonfiles/Contact.json";

export const Required = () => {
  return (
    <>
      <span style={{ color: "red" }}> *</span>
    </>
  );
};

const Option = (props) => (
  <components.Option {...props} className="country-option">
    <img src={props.data.icon} alt="logo" className="country-logo" />
    {props.data.label}
  </components.Option>
);
const Contact = () => {
  const HeroSectionImage1 = Links?.Contact[0].url;
  const services2 = Links?.Contact[1].url;
  const HeroSectionImagealt = Links?.Contact[0].alt;

  const [loading, setLoading] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const navigate = useNavigate();
  const blockNumbers = (val) => {};
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    message: "",
    country_id: "",
  });
  const { heroSection, setHeroSection } = useContext(createcontext);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);
  const recaptchaRef = useRef(null);
  // contact number operation
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  useEffect(() => {
    setFormData({ ...formData, country_id: countries?.[0]?.id });
  }, [countries]);
  const handleChange = (value) => {
    setSelectedCountry(value);
    setFormData({ ...formData, country_id: value?.id });
  };
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}api/v1/public/countries`)
      .then((response) => response.json())
      .then((data) => {
        const countriesData = data.data.countries
          .filter((country) => country.id === 101 || country.id === 231)
          .map((country) => ({
            value: country.shortname,
            label: "+" + country.phonecode,
            id: country.id,
            icon: `https://flagsapi.com/${country.shortname}/shiny/64.png`,
          }));
        setCountries(countriesData);
        setSelectedCountry(countriesData[0]);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);
  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <img src={props.data.icon} alt="s-logo" className="selected-logo" />
      {children}
    </components.SingleValue>
  );

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 992);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setHeroSection(true);
  }, []);
  useEffect(() => {
    reset(formData);
  }, [formData]);

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phone_number: "",
      message: "",
      country_id: "",
    });
    reset({});
  };

  const onFormSubmit = async (form) => {
    if (!captchaResponse) {
      toast.error("Please resolve the captcha");
      return false;
    }
    const { name, email, country_id, phone_number, message } = form;
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("country", country_id);
      formData.append("phone_number", phone_number);
      formData.append("message", message);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/add-contactUs`, {
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        toast.error("Failed to submit form");
        throw new Error("Failed to submit form");
      }
      setLoading(false);
      toast.success("Submited Successfully");
      resetForm();
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      setCaptchaResponse(null);
    } catch (error) {
      console.log(error);
      toast.error("Failed to submit form");
    }
  };

  return (
    <Block className="">
      <div
        className="d-flex flex-column justify-content-center align-center h-500px"
        style={{
          backgroundImage: `url(${services2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h3 className="ff-alt fw-semibold text-capitalize text-center text-white fs-1">{contact?.CRM?.section1.map((item) => item?.title1)}</h3>
      </div>
      <div className="d-flex flex-column align-center justify-center w-100 my-4" id="contact">
        <Card className="px-5 py-3">
          <BlockTitle className="fs-2 text-center">{contact?.CRM?.section1.map((item) => item?.title2)} </BlockTitle>
          <p className="my-1">
          {contact?.CRM?.section1.map((item) => item?.text)}
          </p>
        </Card>
        <Row className="g-2 m-0 w-100 pb-4">
          <Col md={12} lg={6} className="p-0 pe-lg-2">
            {
              <div className="h-100">
                <div className="d-flex rounded-3 h-100">
                  <img
                    src={HeroSectionImage1}
                    alt={HeroSectionImagealt}
                    style={{ borderTopRightRadius: "100px", borderBottomRightRadius: "100px" }}
                    className="d-none d-lg-block w-100"
                  />
                  <img src={HeroSectionImage1} alt="HeroSectionImage" className="d-lg-none h-80 w-100" />
                </div>
              </div>
            }
          </Col>
          <Col md={12} lg={6} className={`leaflet-bar mx-0 p-0 ${!isLargeScreen ? "" : "contactfrm"}`}>
            <div className="mt-4">
              <form noValidate onSubmit={handleSubmit(onFormSubmit)} className="">
                <Row className="g-0">
                  <Col lg={6} className="justify-center">
                    <div className="form-group w-75">
                      <div className="form-control-wrap my-2">
                        <label htmlFor="event_message">
                          Full Name <Required />
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="name"
                          placeholder="Enter Full Name"
                          {...register("name", {
                            required: "This field is required",
                            pattern: {
                              value: /^[A-Za-z\s]+$/,
                              message: "Only alphabets and spaces are allowed",
                            },
                            minLength: {
                              value: 3,
                              message: "Please enter at least 3 characters",
                            },
                          })}
                          value={formData.name}
                          onChange={(e) => {
                            setFormData({ ...formData, name: e.target.value });
                            clearErrors(e.target.name);
                          }}
                          maxLength={30}
                        />
                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} className="justify-center">
                    <div className="form-group w-75">
                      <div className="form-control-wrap my-2">
                        <label htmlFor="event_message">
                          Email <Required />
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="email"
                          placeholder="Enter Email"
                          {...register("email", {
                            required: "This is required",
                            pattern: {
                              value:
                                // eslint-disable-next-line
                                /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                              message: "Enter a valid email address",
                            },
                            maxLength: { value: 255, message: "Email must not exceed 255 characters" },
                          })}
                          value={formData.email}
                          onChange={(e) => {
                            setFormData({ ...formData, email: e.target.value });
                            clearErrors(e.target.email);
                          }}
                        />
                        {errors.email && <span className="invalid">{errors.email.message}</span>}
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} className="justify-center">
                    <div className="form-group w-75">
                      <div className="form-control-wrap my-2">
                        <label htmlFor="event_message">
                          Contact Number <Required />
                        </label>
                        <div className="d-flex">
                          <Select
                            className="w-65"
                            value={selectedCountry}
                            options={countries}
                            onChange={handleChange}
                            styles={{
                              option: (provided, state) => ({
                                ...provided,
                                color: state.isSelected ? "white" : "black",
                                backgroundColor: state.isSelected ? "#007bff" : "white",
                                display: "flex",
                                alignItems: "center",
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                display: "flex",
                                alignItems: "center",
                              }),
                            }}
                            components={{
                              Option,
                              SingleValue,
                            }}
                          />
                          <input
                            className="form-control"
                            type="number"
                            id="phone_number"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            placeholder="Enter Contact Number"
                            {...register("phone_number", {
                              required: "This is required",
                              maxLength: {
                                value: 15,
                                message: "You rich maximum contact number limit!",
                              },
                            })}
                            value={formData.phone_number}
                            onChange={(e) => {
                              if (e.target.value.length <= 15) {
                                setFormData({ ...formData, phone_number: e.target.value });
                              } else {
                                blockNumbers();
                              }
                            }}
                          />
                        </div>
                        {errors.phone_number && (
                          <span className="ff-italic text-danger" style={{ fontSize: "12px" }}>
                            {errors.phone_number.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} className="justify-center">
                    <div className={`form-group ${!isLargeScreen ? "w-75" : "w-90 px-2"}`}>
                      <div className="form-control-wrap my-2">
                        <label htmlFor="event_message">How can we help you?</label>
                        <textarea
                          className="form-control h-100px"
                          type="text"
                          id="event_message"
                          placeholder="Enter Event Message"
                          maxLength={500}
                          value={formData.message}
                          onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} className="justify-center">
                    <div className="form-group w-90 px-2 justify-center">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_CAPTCHA_GOOGLE_CAPTCHA_SITE_KEY}
                        onChange={(response) => {
                          setCaptchaResponse(response);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="justify-center my-2">
                  {loading ? (
                    <Button disabled color="primary" className="justify-center w-50">
                      <Spinner size="sm" />
                      <span>Loading...</span>
                    </Button>
                  ) : (
                    <Button color="primary" className="justify-center rounded w-50" type="submit">
                      Submit
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
      <Card className="card-bordered mt-0 py-2">
        <RoleSection />
      </Card>
    </Block>
  );
};

export default Contact;
