import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Row, Spinner } from "reactstrap";
import { BlockTitle, Icon } from "../../components/Component";
import Cookies from "js-cookie";
import { createcontext } from "../Index";
import Tiktok from "../../images/tiktok.png";
import LogoFetch from "../header/LogoFetch";
import footerData from "../../jsonfiles/Footer.json";
import { getCompanyFeaturePerformance } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { db } from "./Firebase";
import { doc, onSnapshot, increment, updateDoc, getDoc, setDoc, arrayUnion } from "firebase/firestore";

const Footer = () => {
  const id = useParams();
  const [count, setCount] = useState(0);
  const [ipCheck, setIPCheck] = useState("");

  const ipcheck = async () => {
    try {
      const res = await fetch("https://api.ipify.org?format=json");
      const response = await res.json();
      setIPCheck(response?.ip || "");
    } catch (err) {
      console.log("Error:" + err);
    }
  };
  useEffect(() => {
    ipcheck();
  }, []);
  console.log(ipCheck);

  useEffect(() => {
    const countDocRef = doc(db, "visitorCounts", "countDoc");
    const initializeCount = async () => {
      const docSnap = await getDoc(countDocRef);

      if (!docSnap.exists()) {
        // await setDoc(countDocRef, { count: 1 });
        await setDoc(countDocRef, { count: 1, ips: [ipCheck] });
        setCount(1);
      } else {
        if (docSnap.data().ips && docSnap.data().ips.includes(ipCheck)) {
          setCount(docSnap.data().count);
        } else {
          // updateDoc(countDocRef, { count: increment(1) });
          await updateDoc(countDocRef, {
            count: increment(1),
            ips: arrayUnion(ipCheck),
          });
        }
      }
    };
    const unsubscribe = onSnapshot(countDocRef, (doc) => {
      if (doc.exists()) {
        setCount(doc.data().count);
        console.log(doc?._document?.data?.value?.mapValue?.fields?.count?.integerValue);
        console.log(doc.data().count);
      }
    });
    initializeCount();
    return () => unsubscribe();
  }, []);

  const {
    data: featuredDJs,
    isLoading: featuredDJsDjsLoading,
    isError: featuredDJsDjsError,
  } = useQuery({
    queryKey: ["get-featuredDJsDjs"],
    queryFn: () => getCompanyFeaturePerformance({ type: 5 }),
    staleTime: Infinity,
  });

  const handleCookieSet = (val) => {
    const now = new Date();
    const expires = new Date(now.getTime() + 5 * 1000);
    Cookies.set("serviceId", val, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
  };

  const { heroSection } = useContext(createcontext);

  const getIconColor = (platform) => {
    switch (platform) {
      case "Facebook":
        return "#316FF6";
      case "Instagram":
        return "#E1306C";
      case "LinkedIn":
        return "#0072b1";
      case "Pinterest":
        return "#E60023";
      default:
        return "#000";
    }
  };

  if (!footerData[0]) {
    return null;
  }

  const currentUrl = window.location.pathname || null;

  return (
    <div className="nk-footer p-0">
      {heroSection === false ? (
        ""
      ) : (
        <>
          <div className="container-fluid">
            <Row className="justify-around m-3">
              <Col lg={3} className="justify-center">
                <div className="d-flex flex-column justify-center">
                  <div className="justify-center p-3" style={{ width: "150px" }}>
                    <Link to="/">
                      <LogoFetch />
                    </Link>
                  </div>
                  <div className="d-flex justify-center flex-column mt-2">
                    <BlockTitle className="mx-1 mb-0">
                      <span style={{ fontSize: "18px" }} className="mx-1 fw-bold">
                        {footerData[0].socialMedia?.title || "Follow Us"}
                      </span>
                    </BlockTitle>
                    <div className="d-flex my-1">
                      {footerData[0].socialMedia?.icons?.map((icon) => (
                        <span className="mx-1" key={icon.platform}>
                          <Link to={icon.link} target="_blank">
                            <div className="parentUI">
                              <div className="childUI child-1UI">
                                <button
                                  className="buttonUI btn-2UI align-center"
                                  style={{ color: getIconColor(icon.platform) }}
                                >
                                  {icon.platform === "TikTok" ? (
                                    <img src={Tiktok} alt={icon.platform} />
                                  ) : (
                                    <Icon name={icon.icon} style={{ color: getIconColor(icon.platform) }} />
                                  )}
                                </button>
                              </div>
                            </div>
                          </Link>
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="row justify-between ">
                  {["company", "account", "contact"].map(
                    (sectionKey) =>
                      footerData[0][sectionKey] && (
                        <div className="col-md-3 col-auto p-0" key={sectionKey}>
                          <BlockTitle className="text-primary">
                            <span style={{ fontSize: "18px" }} className="fw-bold">
                              {footerData[0][sectionKey].title}
                            </span>
                          </BlockTitle>
                          <ul className="" style={{ fontSize: "14px" }}>
                            {footerData[0][sectionKey].links.map((linkItem, index) => (
                              <li key={index}>
                                <Link
                                  to={
                                    linkItem.link
                                      ? linkItem.link
                                      : linkItem.name === "Blog"
                                      ? process.env.REACT_APP_BLOG_URL
                                      : process.env.REACT_APP_ACCOUNT_URL + (linkItem?.path || "")
                                  }
                                  className={currentUrl === linkItem.link ? "text-primary" : "infoSection"}
                                  target={linkItem.name === "Blog" ? "_blank" : "_self"}
                                >
                                  {linkItem.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )
                  )}
                </div>
              </Col>
              {/* For IPDM shows the 5 top-rated DJ's list. */}
              <Col lg={3} className="justify-center ps-lg-5 px-0">
                <div className="row justify-between w-100 ps-0 ps-lg-5">
                  <div className="col-md-3 col-auto p-0 w-100">
                    <BlockTitle className="text-primary">
                      <span style={{ fontSize: "18px" }} className="fw-bold">
                        {footerData?.map((item) => item?.highProfileDJs?.title)}
                      </span>
                    </BlockTitle>
                    {featuredDJsDjsLoading ? (
                      <div className="mx-1 align-center h-50">
                        <Spinner size="sm" color="light" />
                        <span className="px-1">Loading...</span>
                      </div>
                    ) : featuredDJsDjsError ? (
                      <div className="h-50 align-center w-60">Error loading DJ's list. Please try again.</div>
                    ) : featuredDJs?.data?.recent_plans?.length ? (
                      featuredDJs?.data?.recent_plans.slice(0, 5).map((item, idx) => (
                        <ul className="" style={{ fontSize: "14px" }} key={idx}>
                          <li>
                            <Link
                              className={
                                "/dj-profiles/specificDj/" + item.user_id === "/dj-profiles/specificDj/" + id?.id
                                  ? "text-primary"
                                  : "infoSection"
                              }
                              to={`${process.env.REACT_APP_PUBLIC_URL}dj-profiles/specificDj/${item.user_id}`}
                            >
                              {item?.user_profile?.company_name}
                            </Link>
                          </li>
                        </ul>
                      ))
                    ) : (
                      <div>{footerData?.map((item) => item?.highProfileDJs?.title1)}</div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="container-fluid p-2 border-top" style={{ fontSize: "14px" }}>
            <Row className="nk-footer-copyright justify-center">
              <Col md={2} className="text-start">
                {/* <p>Visitors count: {count}</p> */}
                <Button className="text-black bg-white border border-black leaflet-bar">
                  {" "}
                  <p className="text-primary pe-1">{`${process.env.REACT_APP_TITLE}`}</p>Visitors: {count}
                </Button>
              </Col>
              <Col md={10} className="text-center align-center justify-center">
                <p className="pe-5">{footerData[0]?.copyright}</p>
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};

export default Footer;
