import React, { useState, useEffect } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Link } from "react-router-dom";
import { Icon } from "../../../../components/Component";
import { useThemeUpdate } from "../../../provider/Theme";
import Cookies from "js-cookie";
import User from "../user/User";
import data from '../../../../jsonfiles/Header.json'

const AppDropdown = () => {
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen(!open);
  };

  let currentUrl;

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }
  const authTokenChk = Cookies.get("authToken");
  function cookieVariable() {
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
    Cookies.set("cookieVal", "clientRole3", {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
  }

  const [links, setLinks] = useState([]);
  useEffect(() => {
    if (data.links.CRM) {
      setLinks(data.links.CRM);
    }
  }, []);
  return (
    <Dropdown isOpen={open} toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#dropdown"
        onClick={(ev) => ev.preventDefault()}
        className="dropdown-toggle nk-quick-nav-icon"
      >
        <div className="icon-status icon-status-na">
          <Icon name="menu-circled"></Icon>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-lg">
      <div className="dropdown-body">
      <ul className="list-apps">
        {links.map((link, index) => (
          <li key={index}>
            <Link
              to={link.name === "Blog" ? process.env.REACT_APP_BLOG_URL : link.path}
              onClick={toggle}
              className={
                currentUrl === link.path
                  ? "text-primary nav-link text-dark"
                  : "nav-link text-dark"
              }
              target={link.name === "Blog" ? "_blank" : "_self"}
            >
              <span className="list-apps-media">
                <Icon name={link.iconname}></Icon>
              </span>
              <span
                className={
                  currentUrl === link.path
                    ? "text-primary text-dark list-apps-title"
                    : "text-dark list-apps-title"
                }
              >
                {link.name}
              </span>
            </Link>
          </li>
        ))}
        {!authTokenChk && (
          <li className="nav-item">
            <Link
              to={`${process.env.REACT_APP_ACCOUNT_URL}login`}
              className="nav-link"
              onClick={cookieVariable}
            >
              <button className="btn btn-primary">Sign In</button>
            </Link>
          </li>
        )}
      </ul>
    </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default AppDropdown;
