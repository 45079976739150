import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "reactstrap";
import { useNavigate } from "react-router";
import PlaceHolderImage from "../../images/Profile_Picture.png";
import { ShimmerContentBlock } from "react-shimmer-effects";
import { Link } from "react-scroll";
import CookieSiteSettings from "../../utils/cookieUtils";
import dj from "../../jsonfiles/Dj.json";

const ListView = ({ profiles, companiesListLoading }) => {
  const navigate = useNavigate();
  const [currency, setCurrency] = useState("sd");
  useEffect(() => {
    const getCurrency = async () => {
      const currencyValue = CookieSiteSettings();
      setCurrency(currencyValue?.other?.currency);
    };
    getCurrency();
  }, []);

  return (
    <div className="mb-3 overflow-auto" style={{ height: "830px", scrollbarWidth: "none" }}>
      {companiesListLoading ? (
        <div className="d-flex flex-column justify-center align-center">
          {Array.from({ length: 5 }).map((_, index) => (
            <ShimmerContentBlock title text cta thumbnailWidth={100} thumbnailHeight={100} />
          ))}
        </div>
      ) : (
        <div>
          {profiles.map((profile) => (
            <Row
              style={{ cursor: "pointer" }}
              key={profile?.id}
              className="my-2 border g-0 rounded-start-5 shawdow leaflet-bar"
              onClick={() => navigate(`/dj-profiles/specificDj/${profile?.id}`)}
            >
              <Col lg={4} sm={6} xs={6} className="px-0">
                <div className="rounded-start-5">
                  <img
                    src={profile?.profile_image_path || PlaceHolderImage}
                    alt={profile?.company_name || "----"}
                    className="img-fluid object-fit-contain rounded-start-5"
                    style={{ height: "300px", width: "100%" }}
                  />
                </div>
                {/* <div
                  className="rounded-start-5"
                  style={{
                    position: "relative",
                    height: "300px",
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${profile?.profile_image_path || PlaceHolderImage})`,
                      filter: "blur(5px)", // Adjust the blur level here
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "100%",
                      width: "100%",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      zIndex: 1,
                    }}
                  />
                  <img
                    src={profile?.profile_image_path || PlaceHolderImage}
                    alt={profile?.company_name || "----"}
                    className="img-fluid object-fit-contain rounded-start-5"
                    style={{
                      height: "300px",
                      width: "100%",
                      position: "relative",
                      zIndex: 2, // Keep this above the blurred background
                    }}
                  />
                </div> */}
              </Col>
              <Col lg={8} sm={6} xs={6} className="d-flex flex-column justify-content-center px-0">
                <div className="px-3">
                  <h5 className="fw-bold mb-0 mb-md-2 fs-2 d-md-block d-none">{profile?.company_name || "----"}</h5>
                  <h5 className="fw-bold mb-1 fs-6 d-md-none">{profile?.company_name || "----"}</h5>

                  <p className="text-muted fs-3 mb-0 mb-md-2 fw-medium d-md-block d-none">
                    {profile?.city || "----"},{profile?.state || "----"}
                  </p>
                  <p className="text-muted fs-7 mb-1 fw-medium d-md-none">
                    {profile?.city || "----"},{profile?.state || "----"}
                  </p>

                  <p className="fs-5 text-secondary d-md-block d-none" style={{ height: "90px", overflow: "hidden" }}>
                    {profile?.uniqueness && profile?.uniqueness.length > 230 ? (
                      <>
                        {profile?.uniqueness.substring(0, 230)}
                        <span className="text-primary fw-bold">...</span>
                      </>
                    ) : (
                      profile?.uniqueness || "----"
                    )}
                  </p>
                  <p className="fs-7 text-secondary d-md-none mb-2" style={{ height: "40px", overflow: "hidden" }}>
                    {profile?.uniqueness && profile?.uniqueness.length > 60 ? (
                      <>
                        {profile?.uniqueness.substring(0, 60)}
                        <span className="text-primary fw-bold">...</span>
                      </>
                    ) : (
                      profile?.uniqueness || "----"
                    )}
                  </p>
                  <div className="row mx-1 w-100">
                    <div className="d-flex align-center col-md-6 col-12 p-0 py-1">
                      <p className="fs-5 d-md-block d-none m-0">
                        Starting from: {currency}{" "}
                        {new Intl.NumberFormat("en-IN", { currency: "INR" }).format(
                          profile?.max_packages?.[0]?.min_package_price || 0
                        )}
                      </p>
                      <p className="fs-7 d-md-none">
                        Starting from: {currency}{" "}
                        {new Intl.NumberFormat("en-IN", { currency: "INR" }).format(
                          profile?.max_packages?.[0]?.min_package_price || 0
                        )}
                      </p>
                    </div>

                    <div className="col-md-6 col-12 p-0 justify-end align-center">
                      <Button
                        color="primary"
                        className="p-0 h-75 px-3 pt-1 pb-3 d-md-block d-none"
                        onClick={() => navigate(`/dj-profiles/specificDj/${profile?.id}`)}
                      >
                        {dj?.CRM?.section5?.map((item) => item?.title1)}
                      </Button>
                      <Button
                        color="primary"
                        className="h-75 d-md-none me-md-0 me-4"
                        style={{ fontSize: "9px" }}
                        onClick={() => navigate(`/dj-profiles/specificDj/${profile?.id}`)}
                      >
                        {dj?.CRM?.section5?.map((item) => item?.title1)}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          ))}
        </div>
      )}
    </div>
  );
};

export default ListView;
