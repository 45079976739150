import contactus1 from "../WhiteLabelimages/contact_us.jpg";
import contactus2 from "../WhiteLabelimages/contact_us2.jpg";
import contactus3 from "../WhiteLabelimages/rolesection1.jpg"
import contactus4 from "../WhiteLabelimages/rolesection2.jpg"
import homeimage1 from "../WhiteLabelimages/DJ_Section3.jpg"
import homevideo1 from '../WhiteLabelimages/vidHM.mp4'
import homevideo2 from '../WhiteLabelimages/vidDJ.mp4'
import aboutvideo1 from '../WhiteLabelimages/vid1.mp4'
import aboutvideo2 from '../WhiteLabelimages/vid2.mp4'
import aboutvideo3 from '../WhiteLabelimages/vid3.mp4'
import aboutvideo4 from '../WhiteLabelimages/vid4.mp4'
import aboutvideo5 from '../WhiteLabelimages/vid5.mp4'
import aboutimage1 from '../WhiteLabelimages/rolesection1.jpg'
import aboutimage2 from '../WhiteLabelimages/rolesection2.jpg'
import businessimage1 from '../WhiteLabelimages/website development.jpg'
import businessimage2 from '../WhiteLabelimages/Digital Marketing.jpg'
import businessimage3 from '../WhiteLabelimages/Mobile App.jpg'
import businessimage4 from '../WhiteLabelimages/graphic.jpg'
import businessimage5 from '../WhiteLabelimages/video-editing.jpg'
import noresult from '../images/No Result.png'


export const Links = {
  Home: [
    { id: "1", type: "image", url: homeimage1 ? homeimage1 : noresult, alt : "DJ Section" },
    { id: "2", type: "video", url:homevideo1 ? homevideo1 : noresult },
    { id: "3", type: "video", url: homevideo2 ? homevideo2 : noresult},
  ],
  About: [
    { id: "1", type: "video", url: aboutvideo1 ? aboutvideo1 : noresult},
    { id: "2", type: "video", url: aboutvideo2 ? aboutvideo2 : noresult},
    { id: "3", type: "video", url: aboutvideo3 ? aboutvideo3 : noresult},
    { id: "4", type: "video", url: aboutvideo4 ? aboutvideo4 : noresult},
    { id: "5", type: "video", url: aboutvideo5 ? aboutvideo5 : noresult },
    { id: "6", type: "image", url: contactus3 ? contactus3 : noresult, alt: ""},
    { id: "7", type: "image", url: contactus4 ? contactus4 : noresult, alt:""},
  ],
  Blog: [{ id: "1", type: "image", url: "", alt: "" }],
  Contact: [
    { id: "1", type: "image", url: contactus1 ? contactus1 : noresult, alt: "HeroSectionImage" },
    { id: "2", type: "image", url: contactus2 ? contactus2 : noresult, alt: ""},
    { id: "3", type: "image", url: contactus3 ? contactus3 : noresult, alt: ""},
    { id: "4", type: "image", url: contactus4 ? contactus4 : noresult, alt: ""},
  ],
  BusinessPortal: [
    { id: "1", type: "image", url: businessimage1 ? businessimage1 : noresult, alt: ""},
    { id: "2", type: "image", url: businessimage2 ? businessimage2 : noresult, alt: ""},
    { id: "3", type: "image", url: businessimage3 ? businessimage3 : noresult, alt: ""},
    { id: "4", type: "image", url: businessimage4 ? businessimage4 : noresult, alt: ""},
    { id: "5", type: "image", url: businessimage5 ? businessimage5 : noresult, alt: ""},
  ],
};
