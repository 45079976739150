import React from "react";
import { Block, BlockTitle, Icon, PreviewCard, Row } from "../../components/Component";
import { Button, Card, Col } from "reactstrap";
// import DjSectionImg from "../../images/DJ_Section3.jpg";
import { useNavigate } from "react-router";
import { Links } from "../../jsonfiles/Links";
import Home from '../../jsonfiles/Home.json';

const ClientSection = () => {
  const DjSectionImg =Links?.Home[0].url; 
  const DjSectionImgalt = Links?.Home[0].alt;
  const navigate = useNavigate();

  return (
    <div>
      <Block className="border border-1">
        <PreviewCard className="mx-1 my-1 border-0">
          <Row>
            <Col lg="8" className="">
              <BlockTitle className="fs-2 lead-text mb-3">
              {Home?.CRM?.section4.map((item) => item?.title)} {process.env.REACT_APP_TITLE}{" "}
              </BlockTitle>
              <p>
              {Home?.CRM?.section4.map((item) => item?.Description)}
              </p>
              <div className="border-1 border-primary leaflet-bar m-3 p-3 shadow">
                <div>
                  <span className="fs-3 lead-text">{Home?.CRM?.section4.map((item) => item?.leadtext)} {process.env.REACT_APP_TITLE} ?</span>
                </div>
                <div className="row justify-between px-sm-1 px-0">
                  <div className="col-sm-4 col-auto p-0">
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">{Home?.CRM?.section4.map((item) => item?.text1)}</span>
                    </div>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">{Home?.CRM?.section4.map((item) => item?.text2)}</span>
                    </div>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">{Home?.CRM?.section4.map((item) => item?.text3)}</span>
                    </div>
                  </div>
                  <div className="col-sm-4 col-auto p-0">
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">{Home?.CRM?.section4.map((item) => item?.text4)}</span>
                    </div>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">{Home?.CRM?.section4.map((item) => item?.text5)}</span>
                    </div>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">{Home?.CRM?.section4.map((item) => item?.text6)}</span>
                    </div>
                  </div>
                  <div className="col-sm-4 col-auto p-0">
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">{Home?.CRM?.section4.map((item) => item?.text7)}</span>
                    </div>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">{Home?.CRM?.section4.map((item) => item?.text8)}</span>
                    </div>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <span className="align-center fs-6">{Home?.CRM?.section4.map((item) => item?.text9)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="justify-end px-2 my-1">
                <Button className="text-white bg-primary" color="primary" onClick={() => navigate("/client-section")}>
                {Home?.CRM?.section4.map((item) => item?.buttontext)}
                </Button>
              </div>
            </Col>
            <Col lg="4">
              <div className="justify-center h-100 mt-3 mt-lg-0">
                <img src={DjSectionImg} alt={DjSectionImgalt} className="img-fluid object-fit-cover h-375px" />
              </div>
            </Col>
          </Row>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default ClientSection;
