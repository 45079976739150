import React from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
// import djImg from "../../images/rolesection1.jpg";
// import crowdImg from "../../images/rolesection2.jpg";
import { BlockTitle } from "../../components/Component";
import { Link } from "react-router-dom";
import { Links } from "../../jsonfiles/Links";
import about from "../../jsonfiles/About.json"

const RoleSection = () => {
  const djImg = Links?.Contact[2].url;
  const crowdImg = Links?.Contact[3].url;
  return (
    
    <Card className="py-4">
      <BlockTitle className="mt-1 nk-block-title title fs-2 text-center">{about?.CRM?.section4.map((item)=>item?.title)}</BlockTitle>
      <div className="text-center mb-2 px-2">
        <Card className="p-2">
          <span className="px-5 fs-6 d-none d-md-block">
          {about?.CRM?.section4.map((item)=>item?.text)}
          </span>
          <span className="px-2 fs-12px d-md-none">
          {about?.CRM?.section4.map((item)=>item?.text)}
          </span>
        </Card>
      </div>
      <Row className="h-400px g-0">
        <Col className="p-2" md={6}>
          <div
            className="w-100 h-100"
            style={{
              backgroundImage: `url(${crowdImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
              zIndex: 1,
            }}
          >
            <div
              className=""
              style={{
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 2,
              }}
            />
            <div
              className="d-flex flex-column justify-center align-center h-100 text-white"
              style={{ position: "relative", zIndex: 3 }}
            >
              <p className="mb-0">{about?.CRM?.section4.map((item)=>item?.clienttext1)}</p>
              <BlockTitle className="text-center fs-4 px-3">
              {about?.CRM?.section4.map((item)=>item?.clienttext2)}
              </BlockTitle>
              <Link to={`/client-section`} className="btn btn-primary">
              {about?.CRM?.section4.map((item)=>item?.clienttext3)}
              </Link>
            </div>
          </div>
        </Col>
        <Col className="p-2" md={6}>
          <div
            className="w-100 h-100"
            style={{
              backgroundImage: `url(${djImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
              zIndex: 1,
            }}
          >
            <div
              className=""
              style={{
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 2,
              }}
            />
            <div
              className="d-flex flex-column justify-center align-center h-100 text-white"
              style={{ position: "relative", zIndex: 3 }}
            >
              <p className="mb-0">{about?.CRM?.section4.map((item)=>item?.djtext1)}</p>
              <BlockTitle className="text-center fs-4 px-3">
              {about?.CRM?.section4.map((item)=>item?.djtext2)}
              </BlockTitle>
              <Link to={`/dj-section`} className="btn btn-primary">
              {about?.CRM?.section4.map((item)=>item?.djtext3)}
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default RoleSection;
