import React from "react";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const TermsCondition = () => {
  return (
    <Container className="mt-5">
      <Card>
        <Card.Body>
          <Card.Title>Privacy Policy</Card.Title>
          <p>
            At <strong>I Party DJ Mix</strong>, we are committed to protecting your privacy. This Privacy Policy
            explains how we collect, use, share, and safeguard personal information when you use our website, mobile
            application, and services. By accessing or using our Platform, you agree to the collection and use of your
            information in accordance with this policy.
          </p>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h5>1. Information We Collect</h5>
              <ListGroup>
                <ListGroup.Item>
                  <h6>1.1. Information You Provide to Us</h6>
                  <ul>
                    <li>
                      <b>Contact Information:</b> Your name, email address, phone number, and mailing address.
                    </li>
                    <li>
                      <b>Booking Information:</b> Details of your event, such as event type, date, venue, and
                      preferences.
                    </li>
                    <li>
                      <b>Payment Information:</b> Payment card details or other financial information required to
                      process bookings.
                    </li>
                    <li>
                      <b>Account Information:</b> Username, password, and profile information (including social media
                      profiles if you sign in through third-party services).
                    </li>
                    <li>
                      <b>Communications:</b> Any feedback, inquiries, or communications you send through the Platform,
                      including customer support requests.
                    </li>
                  </ul>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>1.2. Information We Collect Automatically</h6>
                  <ul>
                    <li>
                      <b>Device Information:</b> Information about the device you use to access the Platform, including
                      IP address, operating system, browser type, and device identifiers.
                    </li>
                    <li>
                      <b>Usage Data:</b> Information about your interactions with the Platform, including pages viewed,
                      time spent, links clicked, and other usage patterns.
                    </li>
                    <li>
                      <b>Cookies and Similar Technologies:</b> We use cookies, pixels, and similar tracking technologies
                      to enhance your experience, remember your preferences, and understand how our Platform is being
                      used. You can control cookie preferences through your browser settings.
                    </li>
                  </ul>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>1.3. Information from Third-Party Sources</h6>
                  <ul>
                    <li>
                      <b>Social Media:</b> If you sign up via Facebook, Google, or another social media account, we may
                      receive certain profile information, such as your name, email address, and public profile.
                    </li>
                  </ul>
                </ListGroup.Item>
              </ListGroup>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>2. How We Use Your Information</h5>
              <ListGroup>
                <ListGroup.Item>
                  <h6>2.1. To Provide Our Services</h6>
                  <ul>
                    <li>
                      <b>Booking Management:</b> To facilitate bookings between clients and DJs, including sending
                      confirmation emails, event reminders, and updates.
                    </li>
                    <li>
                      <b>Payment Processing:</b> To securely process payments and manage billing.
                    </li>
                    <li>
                      <b>User Account Maintenance:</b> To create, manage, and secure your user account.
                    </li>
                  </ul>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>2.2. To Improve Our Services</h6>
                  <ul>
                    <li>
                      <b>Analytics and Performance:</b> To monitor usage patterns, conduct data analysis, and improve
                      the Platform’s functionality and user experience.
                    </li>
                    <li>
                      <b>Personalization:</b> To tailor content, offers, and recommendations to suit your preferences.
                    </li>
                  </ul>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>2.3. To Communicate with You</h6>
                  <ul>
                    <li>Customer Support: To respond to inquiries, resolve issues, and provide assistance.</li>
                    <li>
                      Marketing and Promotions: To send promotional emails, newsletters, and special offers (with your
                      consent).
                    </li>
                  </ul>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>2.4. Legal Compliance</h6>
                  <ul>
                    <li>Fraud Prevention: To detect, prevent, and address fraud or other illegal activities.</li>
                    <li>
                      Compliance with Laws: To comply with legal obligations, resolve disputes, and enforce our
                      agreements.
                    </li>
                  </ul>
                </ListGroup.Item>
              </ListGroup>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>3. How We Share Your Information</h5>
              <ListGroup>
                <ListGroup.Item>
                  <h6>3.1. Service Providers</h6>
                  <p>
                    We may share your personal information with trusted third-party service providers who assist us in
                    delivering services, including:
                  </p>
                  <ul>
                    <li>Payment processors (for secure transaction processing)</li>
                    <li>Email service providers (for communication)</li>
                    <li>Hosting and cloud providers (for data storage)</li>
                    <li>Analytics providers (to help us understand usage patterns and improve our services)</li>
                  </ul>
                  <p>
                    These third-party service providers have access to your personal information only to perform
                    specific tasks on our behalf and are obligated to protect it in accordance with this Privacy Policy.
                  </p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>3.2. DJs and Event Organizers</h6>
                  <p>
                    When you make a booking, relevant information (such as your name, contact details, and event
                    information) may be shared with the DJ or event organizer to fulfill the service. DJs and event
                    organizers are only provided the necessary information for the booking and are prohibited from using
                    it for other purposes.
                  </p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>3.3. Business Transfers</h6>
                  <p>
                    In the event of a merger, acquisition, or sale of all or part of our business, your personal
                    information may be transferred as part of the transaction. We will notify you via email or a
                    prominent notice on our Platform if such a change in ownership occurs.
                  </p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>3.4. Legal Requirements</h6>
                  <p>
                    We may disclose your personal information if required to do so by law or in response to a valid
                    legal request from a government authority, including:
                  </p>
                  <ul>
                    <li>To comply with legal obligations</li>
                    <li>To protect our rights or the rights of others</li>
                    <li>To investigate potential violations of our Terms and Conditions</li>
                    <li>To prevent fraud or protect the safety of our users</li>
                  </ul>
                </ListGroup.Item>
              </ListGroup>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>4. Data Security</h5>
              <p>
                We are committed to safeguarding your personal information. We use a combination of technical,
                administrative, and physical security measures to protect your data from unauthorized access,
                disclosure, alteration, or destruction. These measures include:
              </p>
              <ul>
                <li>
                  Encryption: Sensitive information, such as payment details, is encrypted during transmission using
                  Secure Sockets Layer (SSL) technology.
                </li>
                <li>
                  Access Controls: We restrict access to personal data to authorized employees and service providers who
                  need it to perform their roles.
                </li>
                <li>
                  Regular Security Audits: We conduct periodic audits and security assessments to identify and address
                  vulnerabilities.
                </li>
              </ul>
              <p>
                However, no method of transmission over the internet or electronic storage is completely secure, and we
                cannot guarantee absolute security.
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>5. Data Retention</h5>
              <p>
                We retain your personal information only as long as necessary to fulfill the purposes for which it was
                collected or as required by law. Once your information is no longer needed, we will securely delete or
                anonymize it.
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>6. Your Rights and Choices</h5>
              <ListGroup>
                <ListGroup.Item>
                  <h6>6.1. Access</h6>
                  <p>You have the right to request a copy of the personal data we hold about you.</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>6.2. Correction</h6>
                  <p>You can request the correction of inaccurate or incomplete information.</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>6.3. Deletion</h6>
                  <p>
                    You may request the deletion of your personal data in certain circumstances, such as when it is no
                    longer needed for the purposes for which it was collected.
                  </p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>6.4. Data Portability</h6>
                  <p>
                    You can request that we provide your personal data in a structured, commonly used format that can be
                    transferred to another service provider.
                  </p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>6.5. Restriction and Objection</h6>
                  <p>
                    You may request that we restrict the processing of your data or object to its use under certain
                    circumstances.
                  </p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>6.6. Withdrawal of Consent</h6>
                  <p>
                    Where we rely on your consent to process your personal data, you have the right to withdraw that
                    consent at any time.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </ListGroup.Item>

            <ListGroup.Item>
              <h5>7. Cookies and Tracking Technologies</h5>
              <p>
                We use cookies and similar technologies to enhance your experience on the Platform. You can control the
                use of cookies through your browser settings. However, disabling cookies may affect the functionality of
                the Platform.
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>8. International Data Transfers</h5>
              <p>
                If you are accessing the Platform from outside of <strong>India</strong>, your personal data may be
                transferred to, stored, or processed in a country other than your own, including countries that may not
                provide the same level of data protection as your jurisdiction. In such cases, we ensure that
                appropriate safeguards are in place, such as standard contractual clauses.
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>9. Children’s Privacy</h5>
              <p>
                Our Platform is not intended for use by individuals under the age of 18. We do not knowingly collect
                personal information from children under 18. If we become aware that we have inadvertently collected
                such information, we will take steps to delete it promptly.
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>10. Third-Party Links</h5>
              <p>
                Our Platform may contain links to third-party websites or services. We are not responsible for the
                privacy practices or content of these external sites. We encourage you to review their privacy policies
                before providing any personal information.
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>11. Changes to This Privacy Policy</h5>
              <p>
                We may update this Privacy Policy from time to time to reflect changes in our practices or for other
                operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the
                new Privacy Policy on our Platform and updating the effective date at the top. We encourage you to
                review this Privacy Policy periodically for any updates.
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>12. Contact Us</h5>
              <p>
                If you have any questions or concerns about this Privacy Policy or our data practices, please contact us
                at:
              </p>
              <ul>
                <li>
                  <b>Email:</b> support@ipartydjmix.in
                </li>
                <li>
                  <b>Phone:</b> +91 9156362425
                </li>
                <li>
                  <b>Address:</b> Office No 204, Eden Garden Commercial Complex Near Khanvilkar Petrol Pump, Nagala
                  Park, Kolhapur 416003 Maharashtra, India.
                </li>
              </ul>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TermsCondition;
